import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './RegisterAdmin.css'; // Import the CSS for styling

const RegisterAdmin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setModalMessage(''); // Reset message before request

    try {
      const response = await axios.post('https://thekingsgym.id/api/register-admin.php', {
        username,
        password,
      });

      // Debugging: Log the response to see the full structure
      console.log(response.data);

      if (response.data.status === 'success') {
        setModalMessage('Registration successful! You can now log in.');
      } else {
        setModalMessage(response.data.message || 'Registration failed. Please try again.');
      }
    } catch (error) {
      console.error("Error registering:", error);
      setModalMessage('Registration failed. Please try again.');
    } finally {
      setLoading(false);
      setShowModal(true); // Show modal after processing
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // Optionally reset the form after closing the modal
    setUsername('');
    setPassword('');
    if (modalMessage.includes('successful')) { // Check if the message indicates success
      navigate('/login'); // Navigate to the Login page
    }
  };

  return (
    <div className="container mt-5">
      <div className="register-box">
        <h2 className="text-center">Register Admin</h2>
        <form onSubmit={handleRegister} className="mt-4">
          <div className="mb-3">
            <label className="form-label">Username:</label>
            <input
              type="text"
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Password:</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? 'Registering...' : 'Register'}
          </button>
        </form>

        {/* Modal for success or failure */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Registration Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default RegisterAdmin;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const WelcomePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Memeriksa token di localStorage
        const token = localStorage.getItem('token');
        if (!token) {
            // Jika tidak ada token, arahkan ke halaman login
            navigate('/login');
        }
    }, [navigate]);

    return (
        <div style={{ border:'solid 1px', borderRadius:'10px', borderColor:'#CBCCCF', padding:'20px', margin:'20px', boxShadow:'inherit'}}>
            <h5>Assalamu'alaikum King's!</h5>
            <hr />
            <p>Selamat datang di dashboard admin The King's Gym.</p>
        </div>
    );
};

export default WelcomePage;

import React, { useState, useEffect } from 'react';
import './Dashboard.css'; // Sesuaikan dengan stylesheet yang ada

const LogTrainer = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [nameFilter, setNameFilter] = useState('');
  const [phoneFilter, setPhoneFilter] = useState('');
  const [startDateSummary, setStartDateSummary] = useState('');
  const [endDateSummary, setEndDateSummary] = useState('');

  useEffect(() => {
    fetch('https://thekingsgym.id/api/trainer/getLogTrainer.php')
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  // Fungsi untuk menghitung durasi check-in dan check-out
  const calculateDuration = (checkIn, checkOut) => {
    if (!checkIn || !checkOut) return '-';
    const start = new Date(checkIn);
    const end = new Date(checkOut);
    const durationMs = end - start;
    const hours = Math.floor(durationMs / (1000 * 60 * 60));
    const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours} jam ${minutes} menit`;
  };

  // Fungsi untuk filter data berdasarkan rentang tanggal
  const filteredData = data.filter(item => {
    const itemDate = new Date(item.tanggal);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    // Filter berdasarkan tanggal
    if (start && itemDate < start) return false;
    if (end && itemDate > end) return false;

    // Filter berdasarkan nama
    if (nameFilter && !item.nama.toLowerCase().includes(nameFilter.toLowerCase())) return false;

    // Filter berdasarkan phone
    if (phoneFilter && !item.phone.includes(phoneFilter)) return false;

    return true;
  });

  // Fungsi untuk mengonversi data ke format CSV dan mengunduhnya
  const downloadCSV = () => {
    const headers = ["Nama", "Phone", "Tanggal", "Jam Check-In", "Jam Check-Out", "Durasi"];
    const rows = filteredData.map(item => [
      item.nama || 'Tidak Tersedia',
      item.phone,
      item.tanggal,
      item.check_in ? new Date(item.check_in).toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '-',
      item.check_out ? new Date(item.check_out).toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '-',
      calculateDuration(item.check_in, item.check_out)
    ]);

    const csvContent = [headers, ...rows].map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "logtrainer_data.csv";
    link.click();
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = String(date.getHours()).padStart(2, '0');  // Menambahkan 0 di depan jika jam < 10
    const minutes = String(date.getMinutes()).padStart(2, '0');  // Menambahkan 0 di depan jika menit < 10
    return `${hours}:${minutes}`;  // Format jam:menit
  };

  // Menghitung jumlah durasi berdasarkan phone
  const summaryData = filteredData.reduce((acc, item) => {
    const phone = item.phone;
    const duration = calculateDuration(item.check_in, item.check_out);
    if (!acc[phone]) {
      acc[phone] = { phone, nama: item.nama, totalDurasi: 0 };
    }
    if (duration !== '-') {
      const [hours, minutes] = duration.split(' jam ');
      const [minutesPart] = minutes.split(' menit');
      const totalMinutes = parseInt(hours) * 60 + parseInt(minutesPart);
      acc[phone].totalDurasi += totalMinutes;
    }
    return acc;
  }, {});

  // Tabel Kesimpulan
  const summaryList = Object.values(summaryData).map(item => ({
    nama: item.nama,
    phone: item.phone,
    totalDurasi: item.totalDurasi
  }));

  // Filter kesimpulan berdasarkan rentang tanggal
  const filteredSummaryData = summaryList.filter(item => {
    const itemDate = new Date(item.tanggal);
    const start = startDateSummary ? new Date(startDateSummary) : null;
    const end = endDateSummary ? new Date(endDateSummary) : null;

    // Filter berdasarkan tanggal
    if (start && itemDate < start) return false;
    if (end && itemDate > end) return false;

    return true;
  });

  // Urutkan berdasarkan durasi total (menit) dari yang terbesar
  const sortedSummaryData = filteredSummaryData.sort((a, b) => b.totalDurasi - a.totalDurasi);

  return (
    <div className="logtrainer-container" style={{ margin: '20px' }}>
      <h5 style={{
        borderRadius: '20px',
        backgroundColor: '#dfb455',
        padding: '5px 5px 5px 20px',
        marginBottom: '10px'
      }}>Data Log Trainer</h5>

      <div style={{ display: 'flex', justifyContent: 'space-between', }}>
        
        {/* Tabel Log Trainer */}
        <div style={{ width: '75%', alignItems: 'center' }}>
        
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Filter Tanggal */}
        <div style={{ marginBottom: '0px' }}>
          <label style={{ paddingRight: '10px' }}><b>Tanggal</b></label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <span> hingga </span>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            style={{ marginLeft: '10px' }}
          />
        </div>

        {/* Tombol Download CSV */}
        <button onClick={downloadCSV} style={{
          backgroundColor: 'black',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '5px',
          cursor: 'pointer',
          marginBottom: '10px'
        }}>Download CSV</button>
      </div>



          <div className="table-container">
            <table className="data-table" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>
                    <span>Nama</span>
                    <input
                      type="text"
                      value={nameFilter}
                      onChange={(e) => setNameFilter(e.target.value)}
                      placeholder="Cari nama..."
                      style={{ width: '100%', borderRadius: '5px', borderColor: '#CBCCCF' }}
                    />
                  </th>
                  <th>
                    <span>Phone</span>
                    <input
                      type="text"
                      value={phoneFilter}
                      onChange={(e) => setPhoneFilter(e.target.value)}
                      placeholder="Cari phone..."
                      style={{ width: '100%', borderRadius: '5px', borderColor: '#CBCCCF' }}
                    />
                  </th>
                  <th>Tanggal</th>
                  <th>Jam Check-In</th>
                  <th>Jam Check-Out</th>
                  <th>Durasi</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <tr key={item.id}>
                      <td>{item.nama || 'Tidak Tersedia'}</td>
                      <td>{item.phone}</td>
                      <td>
                        {item.tanggal
                          ? new Date(item.tanggal).toLocaleDateString('id-ID', {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric'
                          })
                          : '-'}
                      </td>
                      <td>{item.check_in ? formatTime(item.check_in) : '-'}</td>
                      <td>{item.check_out ? formatTime(item.check_out) : '-'}</td>
                      <td>{calculateDuration(item.check_in, item.check_out)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: 'center' }}>Tidak ada data yang ditemukan.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Tabel Kesimpulan */}
        <div style={{ width: '24%' }}>
        <h4 style={{ backgroundColor:'black', color:'white', padding:'7px', borderRadius:'5px'}}>Leaderboard</h4>
          <div className="table-container">
            <table className="data-table" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Nama</th>
                  <th>Durasi</th>
                </tr>
              </thead>
              <tbody>
                {sortedSummaryData.length > 0 ? (
                  sortedSummaryData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.nama}</td>
                      <td>{`${Math.floor(item.totalDurasi / 60)} jam ${item.totalDurasi % 60} menit`}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" style={{ textAlign: 'center' }}>Tidak ada data kesimpulan.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogTrainer;

import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setModalMessage('');

    try {
      const response = await axios.post('https://thekingsgym.id/api/login-superadmin.php', {
        username,
        password,
      });
      console.log(response.data);

      if (response.data.status === 'success') {
        // Simpan token atau status login di localStorage
        localStorage.setItem('token', response.data.token || 'userLoggedIn'); // gunakan token yang dikembalikan dari API jika ada

        setModalMessage('Login successful! Redirecting...');
        setTimeout(() => navigate('/'), 2000); 
      } else {
        setModalMessage(response.data.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setModalMessage('Login failed. Please try again.');
    } finally {
      setLoading(false);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="login-container">
      <div className="login-box shadow">
        <h2 className="text-center">Admin Login</h2>
        <form onSubmit={handleLogin} className="mt-3">
          <div className="mb-3">
            <label className="form-label">Username:</label>
            <input
              type="text"
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Password:</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>

        {/* Modal for success or failure */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Login Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Login;

import React, { useState } from 'react';
import { Navbar, Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FaSignOutAlt, FaBars } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './NavbarHead.css'; // Import file CSS tambahan jika diperlukan

const NavbarHead = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate(); // Menggunakan useNavigate untuk redirect

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleHamburgerClick = () => {
    alert("Hamburger Clicked"); // Alert message
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Menghapus token dari localStorage
    navigate('/login'); // Redirect ke halaman login
  };

  return (
    <Navbar className="navbar" expand="md">
      <NavLink href="/" className="navbar-link" onClick={handleHamburgerClick}>
        <FaBars className="hamburger-icon" /> {/* Hamburger Icon */}
      </NavLink>
      <Nav className="ml-auto" navbar>
        <NavItem>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle tag="span" className="navbar-link">
              <img
                src="/avatar-admin.png" // Update with the path to your profile picture
                alt="Profile"
                className="profile-pic"
              />
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem header>Profile Options</DropdownItem>
              <DropdownItem href="/profile">Profile</DropdownItem>
              <DropdownItem divider />
              <DropdownItem 
                onClick={handleLogout} // Menambahkan onClick untuk handleLogout
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} // Added flex styling
              >
                <div>Log Out</div>
                <div>
                  <FaSignOutAlt className="icon" /> {/* Log Out Icon */}
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default NavbarHead;

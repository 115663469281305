// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Sidebar from './components/Sidebar';
import './App.css'; // Import CSS file for styling
import NavbarComponent from './components/NavbarHead';
import WelcomePage from './pages/Welcome';
import Login from './pages/LoginAdmin';
import Register from './pages/RegisterAdmin';
import LogTrainer from './pages/logtrainer';

const App = () => {
  const location = useLocation();

  // Function to check if Sidebar and Navbar should be displayed
  const shouldDisplaySidebarAndNavbar = () => {
    // Add paths where you want to hide Sidebar and Navbar
    const hiddenPaths = ['/login', '/regis'];
    return !hiddenPaths.includes(location.pathname);
  };

  return (
    <div className="app-container">
      {/* Conditionally render Sidebar */}
      {shouldDisplaySidebarAndNavbar() && <Sidebar />}
      <div className="content">
        {/* Conditionally render Navbar */}
        {shouldDisplaySidebarAndNavbar() && <NavbarComponent />}
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/regis" element={<Register />} />
          <Route path="/logtrainer" element={<LogTrainer />} />
          {/* Add other routes here if necessary */}
        </Routes>
      </div>
    </div>
  );
};

// Wrap App component with Router in a separate RootApp component
const RootApp = () => (
  <Router>
    <App />
  </Router>
);

export default RootApp;

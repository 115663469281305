// src/components/Sidebar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaUserAlt, FaRunning, FaChartBar, FaSignOutAlt } from 'react-icons/fa';
import './Sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar" style={{ backgroundColor:'#312F30'}}>
      <div className="sidebar-header" >
        {/* <h2 style={{ padding:'20px' }}>The Kings Gym</h2> */}
        <img src="/logo-gym.png" alt="Gym Logo" style={{ width:'100px', padding:'20px 0px 0px 0px'}} />
        <hr/>
      </div>
      <div className="sidebar-menu-container"> {/* Scrolling container */}
        <div className="scroll-hidden" style={{ height: '100%', overflowY: 'scroll' }}>
        <ul className="sidebar-menu">
          <li>
            <NavLink to="/" exact className="sidebar-link" activeClassName="active">
              <FaHome className="icon" />
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard" className="sidebar-link" activeClassName="active">
              <FaUserAlt className="icon" />
              <span>Registrasi</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/logtrainer" className="sidebar-link" activeClassName="active">
              <FaRunning className="icon" />
              <span>Trainer</span>
            </NavLink>
          </li>
        </ul>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Sidebar;

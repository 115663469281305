import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import { FaEnvelope, FaWhatsapp, FaCreditCard, FaMoneyBillWave, FaUser } from 'react-icons/fa';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [nameFilter, setNameFilter] = useState('');
  const [phoneFilter, setPhoneFilter] = useState('');

  const [membershipCounts, setMembershipCounts] = useState({
    harian: 0,
    bulanan: 0,
    tigaBulan: 0,
    enamBulan: 0,
    tahunan: 0,
  });

  // Keterangan manual
  const membershipDescriptions = {
    harian: 'Harian',
    bulanan: 'Bulanan',
    tigaBulan: '3 Bulan',
    enamBulan: '6 Bulan',
    tahunan: 'Tahunan',
  };

  // Fungsi untuk memeriksa autentikasi
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('token'); // Atau nama lain yang Anda gunakan untuk token

    if (!isAuthenticated) {
      // Redirect ke halaman login jika tidak ada token
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    // Panggil API untuk mengambil data registrasi jika sudah login
    fetch('https://thekingsgym.id/api/getRegistrations.php')
    .then((response) => response.json())
    .then((data) => {
      // Urutkan data berdasarkan ID secara menurun
      const sortedData = data.sort((a, b) => b.id - a.id);
      setData(sortedData);

      // Hitung jumlah setiap jenis membership
      const counts = {
        harian: data.filter(item => item.membership_type === 'Harian').length,
        bulanan: data.filter(item => item.membership_type === 'Bulanan').length,
        tigaBulan: data.filter(item => item.membership_type === '3 Bulan').length,
        enamBulan: data.filter(item => item.membership_type === '6 Bulan').length,
        tahunan: data.filter(item => item.membership_type === 'Tahunan').length,
      };
      setMembershipCounts(counts);

    })
    .catch((error) => console.error('Error fetching data:', error));
}, []);

  const handleChatClick = async (id, phone) => {
    try {
      const response = await axios.post('https://thekingsgym.id/api/update-last-hit.php', { id });
      if (response.data.success) {
        window.open(`https://wa.me/${phone.replace(/\D/g, '')}`);
      } else {
        console.error('Failed to update lastHit:', response.data.error);
      }
    } catch (error) {
      console.error("Error updating lastHit", error);
    }
  };

  const formatTimeDifference = (timestamp) => {
    const lastHitDate = new Date(timestamp);
    const now = new Date();
    const diffMs = now - lastHitDate;

    if (diffMs < 60000) return `Baru saja`;
    const diffMins = Math.floor(diffMs / 60000);
    if (diffMins < 60) return `${diffMins} mnt`;
    const diffHours = Math.floor(diffMins / 60);
    if (diffHours < 24) return `${diffHours} jam`;
    const diffDays = Math.floor(diffHours / 24);
    return `${diffDays} hari`;
  };

  // Filter data berdasarkan nama dan nomor telepon
  const filteredData = data.filter(item =>
    item.full_name.toLowerCase().includes(nameFilter.toLowerCase()) && // Memfilter berdasarkan nama
    item.phone.includes(phoneFilter) // Memfilter berdasarkan nomor telepon
  );

  return (
    <div className="dashboard-container">
      <h5
        style={{
          borderRadius:'20px',
          backgroundColor: '#dfb455',
          padding:'5px 5px 5px 20px',
          marginBottom:'10px'
        }}
      >Data Registrasi The King's Gym</h5>

      {/* Kotak-kotak jumlah membership */}
      <div className="membership-stats" style={{ display: 'flex', gap: '20px', marginBottom: '10px' }}>
        <div
          style={{ 
            border: '3px solid #000', 
            borderRadius: '10px', 
            padding: '10px', 
            backgroundColor: '#fff',
            textAlign: 'center',
            minWidth: '100px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'  // Tambahkan shadow
          }}
          >
            <h3>{filteredData.length}</h3>
            <span><small>Total Data</small></span>
        </div>
        
        {Object.keys(membershipCounts).map((key) => (
          <div key={key} className="membership-box" style={{ 
            border: '2px solid #dfb455', 
            borderRadius: '10px', 
            padding: '10px', 
            backgroundColor: '#fff',
            textAlign: 'center',
            minWidth: '100px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'  // Tambahkan shadow
          }}>
            <h3>{membershipCounts[key]}</h3>
            <span><small>{membershipDescriptions[key]}</small></span>
          </div>
        ))}
        
      </div>

      {/* Tabel Data */}
      <div className="table-container" style={{ marginTop: '20px' }}>
        <table className="data-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nama <br/>
                <input
                  type="text"
                  placeholder="Filter Nama"
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.target.value)}
                  style={{ width: '100%', borderRadius:'5px', borderColor:'#CBCCCF' }} // Styling untuk input filter
                />
              </th>
              <th>Alamat</th>
              <th style={{ textAlign:'center' }}>Chat <br />
                <input
                  type="text"
                  placeholder="Filter Phone"
                  value={phoneFilter}
                  onChange={(e) => setPhoneFilter(e.target.value)}
                  style={{ width: '100%', borderRadius:'5px', borderColor:'#CBCCCF' }} // Styling untuk input filter
                />
              </th>
              <th>Last Chat</th>
              <th>Mail</th>
              <th>Membership</th>
              <th>Metode</th>
              <th>Trainer?</th>
              <th>Sumber Informasi</th>
              <th>Status</th>
            </tr>
            
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.full_name}</td>
                  <td >{item.address}</td>
                  <td style={{ textAlign: 'center'}}>
                    <div style={{ minWidth:'150px', display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span>{item.phone}</span>
                      <FaWhatsapp
                        style={{ cursor: 'pointer', color: 'green', marginRight: '8px' }}
                        onClick={() => handleChatClick(item.id, item.phone)}
                      />
                    </div>
                  </td>
                  <td style={{ fontSize: '13px' }}>{item.lastHit ? formatTimeDifference(item.lastHit) : '-'}</td>
                  <td style={{ textAlign: 'center' }}>
                    <FaEnvelope
                      style={{ cursor: 'pointer', color: 'blue' }}
                      onClick={() => window.open(`mailto:${item.email}`)}
                    />
                  </td>
                  <td>{item.membership_type}</td>
                  <td style={{ textAlign: 'center' }}>
                    {item.payment_method === 'Transfer' ? (
                      <FaCreditCard style={{ color: 'blue' }} />
                    ) : (
                      <FaMoneyBillWave style={{ color: 'green' }} />
                    )}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {item.interested_in_trainer === "Ya" ? (
                      <FaUser style={{ color: 'green' }} />
                    ) : (
                      <FaUser style={{ color: 'red' }} />
                    )}
                  </td>
                  <td>{item.how_did_you_know || 'Informasi tidak tersedia'}</td>
                  <td>{item.status === '0' ? 'Pending' : 'Approved'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11" style={{ textAlign: 'center' }}>Tidak ada data yang ditemukan.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;
